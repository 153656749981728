import React, { useState } from 'react';
import { Link, useI18next } from 'gatsby-plugin-react-i18next';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import PropTypes from 'prop-types';

import Container from 'components/ui/Container';
import { useStaticQuery, graphql } from 'gatsby';
import * as Styled from './styles';
import FB from './../../assets/images/fb.png';
import Google from './../../assets/images/google.png';
import Apple from './../../assets/images/apple.png';
import Error from './../../assets/images/error.png';
import KwicklyLogo from './../../assets/images/kwickly-logo.png';
import { loginUser } from '../../state/user/user.service';
import Danger from '../ui/Alert/Danger';
import Check from './../../assets/images/icon-check.png';

const LoginPage = ({ locale }) => {
  const gql = useStaticQuery(graphql`
    query {
      strapiLoginPage_en: strapiLoginPage(locale: { eq: "en" }) {
        heading
        sub_heading
        email_address_field_label
        password_field_label
        forgot_password_text
        login_button_text
        continue_with_facebook
        continue_with_google
        continue_with_apple
        signup_link_text
        or_login_with_text
      }
      strapiLoginPage_zh: strapiLoginPage(locale: { eq: "zh" }) {
        heading
        sub_heading
        email_address_field_label
        password_field_label
        forgot_password_text
        login_button_text
        continue_with_facebook
        continue_with_google
        continue_with_apple
        signup_link_text
        or_login_with_text
      }
      strapiErrorMessages_en: strapiErrorMessages(locale: { eq: "en" }) {
        email_address_required
        invalid_email_address
        invalid_password
        invalid_login_credentials
        unknown_error
        server_error_500
        server_error_502
        request_error_422
      }
      strapiErrorMessages_zh: strapiErrorMessages(locale: { eq: "zh" }) {
        email_address_required
        invalid_email_address
        invalid_password
        invalid_login_credentials
        unknown_error
        server_error_500
        server_error_502
        request_error_422
      }
    }
  `);

  const translations = gql[`strapiLoginPage_${locale}`];
  const errTranslations = gql[`strapiErrorMessages_${locale}`];
  const [errors, setErrors] = useState([]);
  const { navigate } = useI18next();

  function checkBtn() {
    const pass = document.getElementById('password').value;
    const email = document.getElementById('email').value;
    const btn = document.getElementById('submitBtn');

    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
      btn.disabled = true;
      btn.classList.add('disabledBtn');
      btn.classList.remove('enabledBtn');
    } else if (!pass) {
      btn.disabled = true;
      btn.classList.add('disabledBtn');
      btn.classList.remove('enabledBtn');
    } else {
      btn.disabled = false;
      btn.classList.remove('disabledBtn');
      btn.classList.add('enabledBtn');
    }
  }

  return (
    <Container section>
      <Styled.Wrapper>
        <Styled.Mobile>
          <Styled.MobileTtitle>{translations.heading}</Styled.MobileTtitle>
          <p className="subTitle">{translations.sub_heading}</p>
        </Styled.Mobile>
        <Styled.Desk>
          <p className="title">{translations.heading}</p>
          <p className="subTitle">{translations.sub_heading}</p>
        </Styled.Desk>

        <Formik
          initialValues={{ email: '', password: '' }}
          validate={(values) => {
            const errors = {};
            if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
              errors.email = errTranslations.invalid_email_address;
            } else if (!values.email) {
              errors.email = errTranslations.email_address_required;
            } else if (!values.password) {
              errors.password = errTranslations.invalid_password;
            }
            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setErrors([]);

            const loginResponse = await loginUser(values.email, values.password)
              .toPromise()
              .catch((ajax) => {
                switch (ajax.status) {
                  case 500:
                    setErrors([errTranslations.server_error_500]);
                    break;
                  case 502:
                    setErrors([errTranslations.server_error_502]);
                    break;
                  case 400:
                    setErrors([errTranslations.request_error_422]);
                    break;
                  case 401:
                    setErrors([errTranslations.invalid_login_credentials]);
                    break;
                  case 422:
                    setErrors([errTranslations.request_error_422]);
                    break;
                  default:
                    setErrors([errTranslations.unknown_error]);
                    break;
                }
              });

            await navigate(`/process-login/?accessToken=${loginResponse.accessToken}`);
          }}
        >
          {({ isSubmitting, values }) => (
            <Form>
              <p className="inputLabel">{translations.email_address_field_label}</p>
              <Field type="email" name="email">
                {({ field, meta: { touched, error } }) => (
                  <div className="relative">
                    <input
                      className={
                        touched && error ? 'errorMsg bg-redu-light border border-redu-light' : ''
                      }
                      id="email"
                      type="type"
                      onKeyUp={checkBtn}
                      {...field}
                    />
                    {touched && error && (
                      <img
                        src={Error}
                        alt="error"
                        className={touched && error ? 'errorIconShow' : 'errorIconHide'}
                      />
                    )}
                    {touched && !error && (
                      <img
                        src={Check}
                        alt="error"
                        className={touched && !error ? 'errorIconShow' : 'errorIconHide'}
                      />
                    )}
                  </div>
                )}
              </Field>
              <ErrorMessage
                name="email"
                component="div"
                className="my-1 pb-2 text-xs text-redu-mdlight"
              />
              <div className="flex justify-between align-bottom">
                <p className="inputLabel passLabel">{translations.password_field_label}</p>
                <p className="inputLabel forgotPass">
                  <Link to={'/forgot-password'} tabIndex={-1}>
                    {translations.forgot_password_text}
                  </Link>
                </p>
              </div>
              <Field type="password" name="password">
                {({ field, meta: { touched, error } }) => (
                  <div className="relative">
                    <input
                      id="password"
                      className={
                        (touched && error) || errors.length > 0
                          ? 'errorMsg bg-redu-light border border-redu-light'
                          : ''
                      }
                      type="password"
                      onKeyUp={checkBtn}
                      {...field}
                    />
                    <img
                      src={Error}
                      alt="error"
                      id="errIcon"
                      className={
                        (touched && error) || errors.length > 0
                          ? 'absolute right-2 top-3 block'
                          : 'absolute right-2 top-3 hidden'
                      }
                    />
                  </div>
                )}
              </Field>

              <button type="submit" id="submitBtn" className="disabledBtn" disabled>
                {translations.login_button_text}
              </button>

              { errors.map((err) => <Danger message={ err }/>) }
            </Form>
          )}
        </Formik>
        <p className="orLogin">{translations.or_login_with_text}</p>
        <Styled.Social fb onClick={() => (window.location.href = '/social/facebook')}>
          {' '}
          <img src={FB} alt="Facebook" /> <span>{translations.continue_with_facebook}</span>{' '}
        </Styled.Social>
        <Styled.Social google onClick={() => (window.location.href = '/social/google')}>
          {' '}
          <img src={Google} alt="Google" /> <span>{translations.continue_with_google}</span>{' '}
        </Styled.Social>
        <Styled.Social apple onClick={() => (window.location.href = '/social/apple')}>
          {' '}
          <img src={Apple} alt="Apple" /> <span>{translations.continue_with_apple}</span>{' '}
        </Styled.Social>
        <p className="orLogin">
          <Link to="/signup">{translations.signup_link_text}</Link>
        </p>
        <p className="flex justify-center m-0 p-0 text-center" style={{color: '#474759'}}>
          {locale === 'en' ? 'Shop Powered By':'店铺供电'}&nbsp;<img src={KwicklyLogo} alt="KwicklyLogo"/>
        </p>
      </Styled.Wrapper>
    </Container>
  );
};

LoginPage.propTypes = {
  locale: PropTypes.string,
};

LoginPage.defaultProps = {
  locale: 'en',
};

export default LoginPage;
