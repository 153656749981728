import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Login from "../components/Login"
import { I18nextContext } from "gatsby-plugin-react-i18next";

const LoginPage: React.FC = () => {
  const { language } = React.useContext(I18nextContext);

  return (
    <Layout page="login" locale={language}>
      <SEO title="Login" />
      <Login locale={language}/>
    </Layout>
  )
}

export default LoginPage
